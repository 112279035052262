"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "HooksContext", {
  enumerable: true,
  get: function get() {
    return _addons.HooksContext;
  }
});
Object.defineProperty(exports, "applyHooks", {
  enumerable: true,
  get: function get() {
    return _addons.applyHooks;
  }
});
Object.defineProperty(exports, "useMemo", {
  enumerable: true,
  get: function get() {
    return _addons.useMemo;
  }
});
Object.defineProperty(exports, "useCallback", {
  enumerable: true,
  get: function get() {
    return _addons.useCallback;
  }
});
Object.defineProperty(exports, "useRef", {
  enumerable: true,
  get: function get() {
    return _addons.useRef;
  }
});
Object.defineProperty(exports, "useState", {
  enumerable: true,
  get: function get() {
    return _addons.useState;
  }
});
Object.defineProperty(exports, "useReducer", {
  enumerable: true,
  get: function get() {
    return _addons.useReducer;
  }
});
Object.defineProperty(exports, "useEffect", {
  enumerable: true,
  get: function get() {
    return _addons.useEffect;
  }
});
Object.defineProperty(exports, "useChannel", {
  enumerable: true,
  get: function get() {
    return _addons.useChannel;
  }
});
Object.defineProperty(exports, "useStoryContext", {
  enumerable: true,
  get: function get() {
    return _addons.useStoryContext;
  }
});
Object.defineProperty(exports, "useParameter", {
  enumerable: true,
  get: function get() {
    return _addons.useParameter;
  }
});

var _addons = require("@storybook/addons");